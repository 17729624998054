import React, { useEffect, useState } from 'react';
import axios from 'axios';
import {useToken} from "../../useToken";
import { API_BASE_URL } from '../../urlConfig';
import garbageIcon from '../../Images/blackGarbageIcon.svg';
import Button from '@mui/material/Button';
import { CircularProgress } from '@mui/material';
import { Department, UserModel } from '../../types';
import Select from "react-select";
import { AddContactDiv, EditContactDeleteCell, EditContactDepartmentCell, EditContactNameCell, EditContactRow, EditContactTable, Wrapper } from './ContactsStyles';

type Props = {
    contactList: UserModel[];
    schoolId: number;
    
}

const customStyles = {
    container: (provided: any) => ({
        ...provided,

        width: '220px', backgroundColor: 'white',
        marginTop: 10,
        marginRight: 10
    }),
    control: (provided: any, state: any) => ({
        ...provided,
        boxShadow: state.isFocused && '1px solid #a1a8ca',
        border: state.isFocused ? '1px solid #a1a8ca' : '1px solid #e5e5ea',
      
       
        '&:hover': {
            border: state.isFocused && '1px solid #a1a8ca',
        },
    }),
    menu: (provided: any) => ({ ...provided, zIndex: 9999, textAlign: 'Left' }),

};
const EditContacts = ({ contactList, schoolId }: Props) => {
    const [note, setNote] = useState('');
    const [loading, setLoading] = useState(false);
    const [invalidNote, setInvalidNote] = useState(false);
    const [success, setSuccess] = useState(false);
    const [response, setResponse] = useState('');
    const [user, setUser] = useState<{ userId: string, name: string }>();
    const [department, setDepartment] = useState<{ departmentId: number, department: string }>();
    const [departmentOptions, setDepartmentOptions] = useState<Department[]>([]);
    const [userOptions, setUserOptions] = useState<UserModel[]>([]);
    const [error, setError] = useState('');


    const ITEM_HEIGHT = 48;
    const ITEM_PADDING_TOP = 8;
    const MenuProps = {
        PaperProps: {
            style: {
                maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
                width: 250,
            },
        },
    };


    const getToken = useToken();
   
    

    const getDepartments = async () => {
        try {
            const localToken = await getToken();

            const { data } = await axios.get(`${API_BASE_URL}/api/Admin/getDepartments`, {
                headers:
                {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${localToken?.accessToken}`
                }
            });
            setDepartmentOptions(data);
        } catch (error: any) {
            setError(error.message || 'An unknown error occurred');
        }
    };

    const getUsers = async () => {
        try {
            const localToken = await getToken();

            const userType = "HES";
            const { data } = await axios.get(`${API_BASE_URL}/api/Admin/getAllUsers/${userType}`, {
                headers:
                {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${localToken?.accessToken}`
                }
            });
            setUserOptions(data);
        } catch (error: any) {
            setError(error.message || 'An unknown error occurred');
        }
    };
    const AddUserDepartment = async () => {


        if (user?.userId != '' && user != undefined && department != undefined && department?.departmentId != 0) {
            const localToken = await getToken();

            axios.post(`${API_BASE_URL}/api/admin/AddUserDepartment/${user?.userId}/${department?.departmentId}/${schoolId}`, {}
                , {

                    headers:
                    {
                        'Authorization': `Bearer ${localToken?.accessToken}`
                    }
                })
                .then(() => {
                    setResponse("Successfully added user department.");
                    setSuccess(true);

                })
                .catch(error => {
                    setError("Error while adding user department " + error?.response?.data);
                    //setResponse(error?.response?.data);

                })
        } else {
            setResponse("Please select a User and a Department.");
        }
    };


    const deleteContact = async (userDepartmentId: number) => {
        const localToken = await getToken();
        setLoading(true);

        axios.post(`${API_BASE_URL}/api/Admin/DeleteUserDepartment/${userDepartmentId}`, { }, {
            headers:
            {
                'Authorization': `Bearer ${localToken?.accessToken}`
            }
        })
            .then(() => {
                setSuccess(true);
                setResponse('Successfully deleted Contact.')
                  setLoading(false);

            })
            .catch(error => {
                // setResponse(error?.response?.data);
                setError(error?.response);
                setLoading(false);
            })
    };

    useEffect(() => {
        getDepartments();
        getUsers();
    }, []);
    return (
        <Wrapper>

            {/*{success ?*/}
            {/*    (<div style={{ color: '#5EB234' }}>Your feedback was successfully submitted!</div>)*/}
            {/*    :*/}
            
                   <EditContactTable>  {contactList.map(contact => (
               <EditContactRow>
                        <EditContactNameCell>{contact.firstName} {contact.lastName}
                           </EditContactNameCell>
                           <EditContactDepartmentCell>{contact.department?.departmentName} 
                           </EditContactDepartmentCell>
                           <EditContactDeleteCell>   <img style={{ cursor: 'pointer' }} title="Delete School Contact" onClick={() => { deleteContact(contact.department?.userDepartmentsId??0) }} height='15px' width='15px' src={garbageIcon} /></EditContactDeleteCell>
                    </EditContactRow> 
                ))}</EditContactTable>
            <AddContactDiv>
            <Select
                options={userOptions?.map(s => ({
                    label: s.firstName + " " + s.lastName,
                    value: s.user_Id,
                }))}
                onChange={(event) => setUser({
                    userId: event?.value ?? '', name: event?.label ??'' })}
                    styles={customStyles}
                    isClearable={true}
                placeholder="Select User"
            />
            <Select
                options={departmentOptions?.map(s => ({
                    label: s.departmentName,
                    value: s.departmentId,
                }))}
                    isClearable={true}
                onChange={(event) => setDepartment({ departmentId: event?.value ?? 0, department: event?.label ?? '' })}
                styles={customStyles}
                placeholder="Select Title"
                />

                <Button variant="contained" style={{ backgroundColor: '#253872', height: '34px', marginTop: '12px' }} onClick={() => (AddUserDepartment())}>Add</Button>
            </AddContactDiv>

            {response && success && (
                <div>
                    <p style={{ color: '#72E889' }}>{response}</p>
                </div>
            )}
            { error && (
                <div>
                    <p style={{ color: 'red', width: '600px' }}>Response: {error}</p>
                </div>
            )}

            
            {loading && <CircularProgress sx={{ color: '#B1B1B1', marginLeft: '25%' }} />}
        </Wrapper>
    );
};

export default EditContacts;