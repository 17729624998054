import React, {useContext, useEffect, useState} from 'react';
import axios from 'axios';
import { useAuth0 } from "@auth0/auth0-react";
import { UserModel, School, Department } from "../../types";
import { Button, Paper  } from '@mui/material';
import SelectedSchoolContext from "../SelectedSchoolContext";
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import SchoolSelector from "../SchoolSelector";
import {useToken} from "../../useToken";
import { HeaderCellUser } from './AdminStyles';
import { useAppSelector } from '../../store';
import { MdAddBusiness, MdEdit, MdSchool } from 'react-icons/md';
import AddSchoolModal from './AddSchoolModal';
import { FaBuilding, FaEdit, FaUser } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';
import EditUserModal from './EditUserModal';
import { API_BASE_URL } from '../../urlConfig';
import { FaPerson } from 'react-icons/fa6';
import AddDepartmentModal from './AddDepartmentModal';
import departmentIcon from '../../Images/departmentIcon.svg';



const ViewUsers = () => {
    const schoolState = useAppSelector((state) => state.school.school);
    const selectedSchoolId = schoolState.id;
    const [openAddSchoolModal, setOpenAddSchoolModal] = useState(false);
    const [openAddDepartmentModal, setOpenAddDepartmentModal] = useState(false);
    const [openEditUserModal, setOpenEditUserModal] = useState(false);
    const [selectedUserId, setSelectedUserId] = useState('');
    const [selectedUser, setSelectedUser] = useState<UserModel>();
    const [selectedUserSchools, setSelectedUserSchools] = useState<School[]>([]);
    const [selectedUserDepartments, setSelectedUserDepartments] = useState<Department[]>([]);
    const [loading, setLoading] = useState(false);
    //const baseUrl = process.env.REACT_APP_API_BASE_URL;
    const {loginWithRedirect, logout, isAuthenticated, getAccessTokenSilently} = useAuth0();
    const [users,setUsers] = useState<UserModel[]>([]);
    const getToken = useToken();
    const navigate = useNavigate();

    //useEffect(() => {
    //   
    //    //console.log(selectedUserSchools);
    //}, []);

    const getUsers = async ( viewAll: boolean) => {
        setLoading(true);

        const localToken = await getToken();

        if (selectedSchoolId) {
            try {
                const { data } = await axios.get(`${API_BASE_URL}/api/Login/getUsers/${selectedSchoolId}/${viewAll}`,
                    {
                        headers:
                        {
                            'Content-Type': 'application/json',
                            'Authorization': `Bearer ${localToken?.accessToken}`
                        }
                    });
             //   console.log('users object', data);
                setUsers(data);
            }
            catch (ex) {
                console.log('Access denied to View Users');
            }
        }
    }


    const updateUserAndOpenModal = async (user: UserModel, callback: () => void) => {
        setSelectedUser(user),
        setSelectedUserId(user.user_Id ?? ""),
        setSelectedUserSchools(user.schools ?? [])
        setSelectedUserDepartments(user.departments ?? [])
        callback();
    };
    return (
        <div>
            {openAddSchoolModal && <AddSchoolModal onClose={() => setOpenAddSchoolModal(false)} userName={selectedUser?.name ?? ""} userId={selectedUserId} selectedSchools={selectedUserSchools} />}
            {openAddDepartmentModal && <AddDepartmentModal onClose={() => setOpenAddDepartmentModal(false)} userName={selectedUser?.name ?? ""} userId={selectedUserId} selectedDepartments={selectedUserDepartments} />}
            {openEditUserModal && selectedUser && <EditUserModal onClose={() => setOpenEditUserModal(false)} user={selectedUser} userId={selectedUserId}  />}
            <div><SchoolSelector /></div>  <br />
            <div style={{ display: 'flex' }}>    <Button variant='contained' style={{ backgroundColor: "#253872" }} onClick={() => (getUsers(false))}>School Users</Button>   <br />
                <Button variant='contained' style={{ backgroundColor: "#253872", marginLeft:'15px' }} onClick={() => (getUsers(true))}>All Users</Button>   <br /></div>
        <br />
            {users && (
                <TableContainer component={Paper} sx={{width:'90%'} }> 
            <Table>
                <TableHead>
                    <TableRow>
                               <TableCell><HeaderCellUser>Name</HeaderCellUser></TableCell>
                               <TableCell><HeaderCellUser>Email</HeaderCellUser></TableCell>
                             {/*  <TableCell><HeaderCellUser>Roles</HeaderCellUser></TableCell>*/}
                               <TableCell><HeaderCellUser>Schools</HeaderCellUser></TableCell>
                                <TableCell><HeaderCellUser>Departments</HeaderCellUser></TableCell>
                               <TableCell><HeaderCellUser>Edit</HeaderCellUser></TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {users.map((user) => (
                        <TableRow key={user.user_Id}>
                            <TableCell>{user?.given_Name} {user?.name} {user.family_Name}</TableCell>
                            <TableCell>{user.email}</TableCell>
                            {/*<TableCell>{user.roles?.map((role) => (role.description + "; "))}</TableCell>*/}
                            <TableCell>{user.schools?.map((school) => (school?.name  + "; "))}</TableCell>
                            <TableCell>{user.departments?.map((dep) => (dep?.departmentName + "; "))}</TableCell>
                            <TableCell>
                                <div style={{ display: 'flex' }}>  <FaUser fontSize={'18px'} color={'#253872'} onClick={() =>
                                    updateUserAndOpenModal(user, () => setOpenEditUserModal(true))}></FaUser>
                                    <FaBuilding style={{ marginLeft:'15px' }} fontSize={'18px'} color={'#253872'} onClick={() => {
                                    updateUserAndOpenModal(user, () => setOpenAddSchoolModal(true))
                                    }}></FaBuilding>
                                    <img src={departmentIcon} style={{ marginLeft: '15px' }} width={'18px'} height={ '18px'} color={'#253872'} onClick={() => {
                                        updateUserAndOpenModal(user, () => setOpenAddDepartmentModal(true))
                                    }}></img>
                                </div>  
                                </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
            )}
        </div>
        );
    
}

export default ViewUsers;