import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import MenuItem from '@mui/material/MenuItem';
import AdbIcon from '@mui/icons-material/Adb';
import { NavLink } from 'react-router-dom';
import logo from '../Images/hecmLogo.svg';
import settingTaupeIcon from '../Images/settingTaupeIcon.svg';
import SelectedSchoolContext from './SelectedSchoolContext';
import { useContext, useEffect, useState } from 'react';
import personIcon from '../Images/personIcon.svg';
import {  useAuth0 } from '@auth0/auth0-react';
import { MdContacts, MdFeedback, MdFileCopy, MdFileUpload, MdHistory, MdImportExport, MdManageAccounts, MdPersonAdd, MdSchool, MdSettings } from 'react-icons/md';
import styled from '@emotion/styled';
import { RiAdminLine } from 'react-icons/ri';
import ListItemIcon from '@mui/material/ListItemIcon';
import Divider from '@mui/material/Divider';
import { useToken } from '../useToken';
import SwitchSchoolModal from './SwitchSchoolModal';
import { persistor, store, useAppDispatch, useAppSelector } from '../store';
import { setSchoolState } from '../store/schoolSlice';
import { API_BASE_URL } from '../urlConfig';

    const TextWrapper = styled.span`
  color: #253872 ;
font-family: 'Open Sans' 
  
`; 
const SelectedSchool = styled.span`
    @media (max-width:800px){
        display: none;
    }
  
`;
//const GridItemStyled = styled(Grid)`
//  &.MuiGrid-item {
//    padding-top: 0;
//     @media (max-width:880px){
//      padding-top: 64px;
//         }
//  }
//`;

function ResponsiveAppBar() {
    const [anchorElNav, setAnchorElNav] = React.useState<null | HTMLElement>(null);
    const [anchorElUser, setAnchorElUser] = React.useState<null | HTMLElement>(null);

    const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorElNav(event.currentTarget);
    };
    const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorElUser(event.currentTarget);
    };

    const handleCloseNavMenu = () => {
        setAnchorElNav(null);
    };

    const handleCloseUserMenu = () => {
        setAnchorElUser(null);
    };
    //const contextValue = useContext(SelectedSchoolContext);
    //if (!contextValue) {
    //    // Handle the case where the context is null
    //    // This could be throwing an error, returning, rendering a different component, etc.
    //    throw new Error("SelectedSchoolContext is null");
    //}
    const schoolState = useAppSelector((state) => state.school.school);
    const dispatch = useAppDispatch();
    // const { isLoadingSchools, setIsLoadingSchools, selectedSchoolId, setSelectedSchoolId, selectedSchoolName, setSelectedSchoolName } = contextValue;
    const selectedSchoolId = schoolState.id;
    const selectedSchoolName = schoolState.name;
    const [isLoadingSchools, setIsLoadingSchools] = useState<boolean>(false);
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    const [openSchoolModal, setOpenSchoolModal] = useState(false);
    const { logout } = useAuth0();
    const { user } = useAuth0();


    const [roles, setRoles] = useState<string[] | string>();
    const getToken = useToken();
    const setUserRoles = async () => {

        const localToken = await getToken();
        setRoles(localToken?.roles);
    };
    //const baseUrl = process.env.REACT_APP_API_BASE_URL;
    const getSchoolsForUser = async () => {
        const localToken = await getToken();
      //  setIsLoadingSchools(true);
        try {
            fetch(`${API_BASE_URL}/api/School/getSchoolsForUser`, {
                headers:
                {
                    'Authorization': `Bearer ${localToken?.accessToken}`
                }
            })
                .then(response => response.json())
                .then(data => {
                   // setSchools(data);
                    setIsLoadingSchools(false);

                if ((schoolState.id == null || schoolState.id == 0) && data.length > 0) {
                      dispatch(setSchoolState({ name: data[0].name, id: data[0].id }));
                    }// Set the first school as the default
                });
        }
        catch ({ message }) {
            console.log('An unknown error occurred' || message);
        } finally {
            setIsLoadingSchools(false);
        }
    }

    useEffect(() => {
        setUserRoles();
        if (selectedSchoolId == 0 || selectedSchoolId == null) {
            getSchoolsForUser();
        }  
    }, [selectedSchoolId]);
    return (
        <AppBar position="static" style={{

            backgroundColor: 'rgb(246 248 255)', color: '#253872',     }} elevation={0} >
            <Container style={{padding:'0px'}} sx={{ width: '100%' }} maxWidth={false} >
                <Toolbar disableGutters>
                    <Box sx={{ flexGrow: 0, marginLeft: 'auto', marginRight: '36px'}}>
                        <SelectedSchool style={{ marginRight: 18, marginTop:'8px' }}> {selectedSchoolName}</SelectedSchool>
                        <Tooltip title="Open settings">
                            <IconButton onClick={handleOpenUserMenu} sx={{ p: 0, backgroundColor: '#fff', width: '40px', height: '40px' }}>
                                {user?.picture && user?.picture != "https://example.com/image.jpg" && <img width="32" height="32" src={user?.picture} alt="" />}
                                {(!user?.picture || user?.picture == "https://example.com/image.jpg") && <img width="32" height="32" src={personIcon} alt="" />}

                            </IconButton>
                        </Tooltip>
                        <Menu
                            sx={{ mt: '45px' }}
                            id="menu-appbar"
                            anchorEl={anchorElUser}
                            anchorOrigin={{
                                vertical: 'top',
                                horizontal: 'right',
                            }}
                            keepMounted
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'right',
                            }}
                            open={Boolean(anchorElUser)}
                            onClose={handleCloseUserMenu}
                            
                        >
                            <NavLink to={"/accountsettings"} style={{ textDecoration: 'auto', }} > <MenuItem onClick={handleCloseUserMenu}>
                                <ListItemIcon>
                                    <MdSettings color='#253872' fontSize="large" />
                                </ListItemIcon>     <TextWrapper>Account Settings</TextWrapper>
                            </MenuItem></NavLink>
                            {/*<MenuItem onClick={() => setOpenSchoolModal(true)}>*/}
                            {/*    <ListItemIcon>*/}
                            {/*        <MdSchool color='#253872' fontSize="large" />*/}
                            {/*    </ListItemIcon>*/}
                            {/*    <TextWrapper>Switch School</TextWrapper>*/}
                            {/*</MenuItem>*/}
                            <NavLink to={"/switchschool"}  style={{ textDecoration: 'auto', }}>
                                <MenuItem onClick={handleCloseUserMenu}>
                                    <ListItemIcon>
                                        <MdSchool color='#253872' fontSize="large" />
                                    </ListItemIcon>
                                    <TextWrapper>Switch School</TextWrapper>
                                </MenuItem></NavLink>

                            {/*<NavLink to={"/contacts"} style={{ textDecoration: 'auto', }}>*/}
                            {/*    <MenuItem onClick={handleClose}>*/}
                            {/*        <ListItemIcon>*/}
                            {/*            <MdContacts color='#253872' fontSize="large" />*/}
                            {/*        </ListItemIcon>*/}
                            {/*        <TextWrapper>HES Contacts</TextWrapper>*/}
                            {/*    </MenuItem></NavLink>*/}


                            {(roles?.includes("SuperAdmin") || roles?.includes("Admin")) && <Divider />}
                            {(roles?.includes("SuperAdmin")) && <NavLink to={"/history"} style={{ textDecoration: 'auto', }}> <MenuItem onClick={handleCloseUserMenu}><ListItemIcon>
                                <MdHistory color='#253872' fontSize="large" />
                            </ListItemIcon>
                                <TextWrapper >History</TextWrapper>   </MenuItem></NavLink>}

                            {(roles?.includes("SuperAdmin") || roles?.includes("Admin") || roles?.includes("Processor")) && <NavLink to={"/admin"} style={{ textDecoration: 'auto', }}>     <MenuItem onClick={handleCloseUserMenu}>
                                <ListItemIcon>
                                    <MdFileCopy color='#253872' fontSize="large"></MdFileCopy> {/*<RiAdminLine fontSize="large" color='#253872' />*/}
                                </ListItemIcon>
                                <TextWrapper>Imports</TextWrapper>
                            </MenuItem></NavLink>}
                            {((roles?.includes("SuperAdmin")) || (roles?.includes("HESManager") )) && <NavLink to={"/feedback"} style={{ textDecoration: 'auto', }}>
                                <MenuItem onClick={handleCloseUserMenu}>
                                    <ListItemIcon>
                                        <MdFeedback fontSize="large" color='#253872' />
                                    </ListItemIcon>
                                    <TextWrapper>Feedback</TextWrapper>
                                </MenuItem></NavLink>}

                            {(roles?.includes("SuperAdmin")) && <NavLink to={"/manageusers"} style={{ textDecoration: 'auto', }}>
                                <MenuItem onClick={handleCloseUserMenu}>
                                <ListItemIcon>
                                    <MdManageAccounts fontSize="large" color='#253872' />
                                </ListItemIcon>
                                <TextWrapper>Manage Users</TextWrapper>
                            </MenuItem></NavLink>}

                            <MenuItem style={{ backgroundColor: 'rgba(186, 153, 118, 0.13)', color: '#BA9976', fontWeight: '700', textAlign: 'center', paddingLeft: '35%' }} onClick={() => { persistor.purge(), logout({ logoutParams: { returnTo: window.location.origin } }) }}>
                               Sign Out
                            </MenuItem>
                        </Menu>
                    </Box>
                </Toolbar>

                {openSchoolModal && <SwitchSchoolModal onClose={() => setOpenSchoolModal(false)} />}

            </Container>
        </AppBar>
    );
}
export default ResponsiveAppBar;
