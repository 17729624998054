import React, { useContext, useEffect, useState } from 'react';
import axios from 'axios';
import { useAuth0 } from "@auth0/auth0-react";
import { Role, UserModel } from "../../types";
import { Paper, Grid, Button, TextField, MenuItem, InputLabel, Select, FormControl } from '@mui/material';
/*import Select from "react-select";*/
import SelectedSchoolContext from "../SelectedSchoolContext";
import SchoolSelector from "../SchoolSelector";
import { useToken } from "../../useToken";
import { useNavigate } from 'react-router-dom';
import '../../styles.css';
import { useAppSelector } from '../../store';
import { API_BASE_URL } from '../../urlConfig';

type Props = {
    currentUserModel: UserModel;
    onClose: ()=> void;
}
const customStyles = {
    container: (provided: any) => ({
        ...provided,
        width: '100%',
    }),
    control: (provided: any, state: any) => ({
        ...provided,
        boxShadow: state.isFocused && '1px solid #253872',
        border: state.isFocused ? '1px solid #253872' : '1px solid #e5e5ea',
        height: 48,
        '&:hover': {
            border: state.isFocused && '1px solid #253872',

        },
    }),
};

const EditUser = ({ currentUserModel, onClose }: Props) => {
    //const contextValue = useContext(SelectedSchoolContext);
    //if (!contextValue) {
    //    // Handle the case where the context is null
    //    // This could be throwing an error, returning, rendering a different component, etc.
    //    throw new Error("SelectedSchoolContext is null");
    //}
    //const { isLoadingSchools, selectedSchoolId } = contextValue;
    const schoolState = useAppSelector((state) => state.school.school);
    const selectedSchoolId = schoolState.id;
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [response, setResponse] = useState(null);
    const [success, setSuccess] = useState(false);
   // const baseUrl = process.env.REACT_APP_API_BASE_URL;
  const [firstNameError, setFirstNameError] = useState<string>("");
    const [lastNameError, setLastNameError] = useState<string>("");
    const [roleError, setRoleError] = useState<string>("");
    const [titleError, setTitleError] = useState<string>("");
    const [currentTitle, setCurrentTitle] = useState<string|undefined>(currentUserModel.given_Name);
    const [error, setError] = useState('');
    const [roles, setRoles] = useState<Role[]>();
    const [userRoleId, setUserRoleId] = useState<string>();

    const validateEmail = (email: string) => {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailRegex.test(email);
    };
    const [emailError, setEmailError] = useState("");

    const validatePassword = (password: string) => {
        const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;
        return passwordRegex.test(password);
    };
    const [userModel, setUserModel] = useState<UserModel>({
        email: "",
        user_metadata: { name: "" },
        blocked: false,
        email_verified: false,
        app_metadata: {},
        given_Name: "",
        family_Name: "",
        name: "",
        nickname: "nickname",
        picture: "https://example.com/image.jpg",
        user_Id: Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15),
        connection: "Username-Password-Authentication",
        password: "",
        verify_email: false,
        schoolId: selectedSchoolId ?? 0,
        role_id: "",
    });


    const selectRoleOptions = [
        { value: "rol_deK6qVjZHyq5X6UZ", label: "School Admin" },
        { value: "rol_2J3g5FtiscBeJZnI", label: "HES User" },
        { value: "rol_LHuQ2CQT4e1s23Ws", label: "HES Admin" }];

    const titles = [
        { value: "Rabbi", label: "Rabbi" },
        { value: "Mrs", label: "Mrs" },
        { value: "Miss", label: "Miss" },
        { value: "Mr", label: "Mr" }];

    const getToken = useToken();

    const updateUser = async () => {
        setLoading(true);
        const localToken = await getToken();
        setUserModel({ ...userModel, user_metadata: { name: userModel.name + " " + userModel.family_Name } })

        axios.post(`${API_BASE_URL}/api/Login/updateUser`, userModel, {
            headers:
            {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${localToken?.accessToken}`
            }
        })
            .then((res) => {
                if (res.status === 200) {
                   // console.log(res);
                    setSuccess(true);
                  //  console.log(success);
                    setLoading(false);
                    alert("Success updating user");
                   
                } else {
                   // console.log('Error updating user!  ');
                   // console.log(res);
                    setResponse(res.data);
                    setLoading(false);
                    alert(res.statusText + " Error updating user---  " + res.data);

                }
            })

            .catch((error) => {
                setResponse(error?.response?.data);
                setLoading(false);
                alert("Error updating user---  " + error?.response?.data);
            });
    }

    const getRoles = async () => {
        try {
            const localToken = await getToken();

            const { data } = await axios.get(`${API_BASE_URL}/api/Admin/GetRoles`, {
                headers:
                {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${localToken?.accessToken}`
                }
            });
            const filteredData = data?.filter((role: { name: string; }) => role.name != 'gmailRule' && role.name != 'Admin');
            setRoles(filteredData);
        } catch (error: any) {
            setError(error.message || 'An unknown error occurred');
        }
    };

    const getUserRoles = async () => {
        try {
            const localToken = await getToken();

            const { data } = await axios.get(`${API_BASE_URL}/api/Admin/GetUserRoles/${currentUserModel.user_Id}`, {
                headers:
                {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${localToken?.accessToken}`
                }
            });
            setUserModel({
                ...currentUserModel, role_id: (data[0].id)
            });
        
        } catch (error: any) {
            setError(error.message || 'An unknown error occurred');
        }
    };
    useEffect(() => {
        getRoles();
        getUserRoles();
        setUserModel(currentUserModel);
        
    }, []);
    return (
        <div>
            <br />
            <Grid container spacing={2}>
                <Grid item >
                    <Paper style={{ padding: 16 }}>
                        <form noValidate autoComplete="off">
                            <span className="pageHeaderAccountSettings" >Edit User</span>
                            <TextField
                                fullWidth
                                label="Email"
                                value={userModel.email}
                                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                    const email = e.target.value;
                                    setUserModel({ ...userModel, email: email });
                                    if (!validateEmail(email)) {
                                        setEmailError("Please enter a valid email.");
                                    } else {
                                        setEmailError("");
                                    }
                                }}
                                disabled={true}
                                error={!!emailError}
                                helperText={emailError}
                                margin={'normal'}
                            />
                            <FormControl margin={'normal'} fullWidth><InputLabel>Title</InputLabel>
                                <Select
                                    value={userModel.given_Name}
                                    label="Title"
                                    error={!!titleError}
                                    
                                    onChange={(e) => setUserModel({ ...userModel, given_Name: (e.target.value) })}                                
                     
                                    style={{textAlign:"left"} }
                                >
                                    {titles &&
                                        titles.map(x => (
                                            <MenuItem value={x.value}>{x.label}</MenuItem>))}
                                </Select></FormControl>

                            <TextField
                                fullWidth
                                label="First Name"
                                value={userModel.name}
                                onChange={(e: React.ChangeEvent<HTMLInputElement>) => setUserModel({ ...userModel, name: e.target.value })}
                                margin={'normal'}
                                error={!!firstNameError}
                                helperText={firstNameError}
                            />
                            <TextField
                                fullWidth
                                label="Last Name"
                                value={userModel.family_Name} margin={'normal'}
                                error={!!lastNameError}
                                helperText={lastNameError}
                                onChange={(e: React.ChangeEvent<HTMLInputElement>) => setUserModel({ ...userModel, family_Name: e.target.value })}
                            />
                  

                            <FormControl margin={'normal'} fullWidth><InputLabel>Assign Role</InputLabel>
                                <Select
                                    defaultValue={userRoleId}
                                    value={userModel.role_id}
                                    label="Assign Role"
                                    error={!!roleError}
                                    onChange={(e) => setUserModel({ ...userModel, role_id: (e.target.value) })}
                                    style={{ textAlign: "left" }}
                                >
                                    {roles &&
                                        roles.map(x => (
                                            <MenuItem value={x.id}>{x.description}</MenuItem>))}
                                </Select></FormControl>


                            <div style={{ marginTop: "10px" }}>     <Button variant="contained" disabled={loading} style={{ backgroundColor: '#253872' }}
                                onClick={() => {
                                    if (userModel.name == '') {
                                        setFirstNameError("Please enter a First Name.");
                                    }
                                    else { setFirstNameError(""); }
                                    if (userModel.family_Name == '') {
                                        setLastNameError("Please enter a Last Name.");
                                    } else {
                                        setLastNameError("");
                                    }
                                    if (userModel.role_id == '') {
                                        setRoleError("Please select a Role.");
                                    } else { setRoleError(""); }
                                    if (userModel.given_Name == '') {
                                        setTitleError("Please select a Title.");
                                    } else { setTitleError(""); }
                                    //if (userModel.password !== verifyPassword) {
                                    //    setPasswordError("Passwords do not match!");
                                    //}
                                    //else if (!validatePassword(userModel.password)) {
                                    //    setPasswordError("Password does not meet the requirements!");
                                    //} else {
                                    //    setPasswordError("");
                                    //}
                                    if ( userModel.name != "" && firstNameError == "" && lastNameError == "" && roleError == "" && userModel.given_Name != "") {
                                        updateUser();
                                    }

                                }} >
                                {loading ? 'Updating...' : 'Update User'}
                            </Button>
                                <Button variant="outlined" style={{ borderColor: '#253872', color: '#253872', marginLeft: '5px' }} onClick={() => onClose()}>Cancel</Button>
                            </div>
                        </form>
                    </Paper>
                </Grid>
            </Grid>
        </div>
    );

}

export default EditUser;