import React, { useState } from 'react';
import styled from '@emotion/styled';
import previousIcon from '../Images/PreviousIcon.svg';
import { IconButton } from '@mui/material';

type Props = {
    onClick: () => void;
}


const Icon = styled.img`
width: 28px;
overflow: hidden;
height: 28px;


`;

const PreviousIcon = ({ onClick }: Props) => {

    return (
        <IconButton onClick={onClick}>

            <Icon src={previousIcon} alt="Next"  />

        </IconButton>
    );
};
export default PreviousIcon;
