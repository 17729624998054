import React from 'react';
import { ModalBody, ModalContent, ModalHeader, ModalOverlay } from '../Contacts/ContactsStyles';
import { CloseButton } from '../CloseButton';
import { UserModel } from '../../types';
import EditContacts from './EditContacts';

type Props = {
    selectedSchoolName: string;
    selectedSchoolId: number;
    contactList: UserModel[];
    onClose: () => void;
}

const EditContactsModal = ({ onClose, selectedSchoolName, selectedSchoolId,contactList }: Props) => {
    return (
        <ModalOverlay onClick={onClose}>
            <ModalContent onClick={(e) => e.stopPropagation()}>
                <ModalHeader>Edit Contacts for {selectedSchoolName}</ModalHeader>
                <CloseButton onClick={onClose} />
                <ModalBody>

                    <EditContacts contactList={contactList} schoolId={selectedSchoolId} />
                </ModalBody>
            </ModalContent>
        </ModalOverlay>
    );
};

export default EditContactsModal;
