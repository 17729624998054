import React, { useContext, useEffect, useState } from 'react';
import { Need, Student, StudentRequirement } from '../../globalTypes/objects';
import axios from 'axios';
import { Tab, Tabs, TabList } from 'react-tabs';
import StudentUploadModal from './StudentUploadModal';
import NoteModal from './NoteModal';
import { useAuth0 } from "@auth0/auth0-react";
import {
    NoteButton,
    ProcessingButton,
    ProcessingButtonWrapper,
    ProcessingNeed,
    Uploaded,
    UploadedNeed,
    Wrapper
} from './ProcessingStyles';
import DownloadFormsModal from './DownloadFormsModal';
import StudentUploadMultipleModal from './StudentUploadMultipleModal';
import SelectedSchoolContext from '../SelectedSchoolContext';
import SchoolSelector from "../SchoolSelector";
import Loader from "../Loader";
import { School } from "../../types";
import DownloadForms from './DownloadForms';
import { useToken } from '../../useToken';
import NeedUploadIcon from './NeedUploadIcon';
import NeedCommentIcon from './NeedCommentIcon';
import '../../styles.css';
import { Box, Card, Paper, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material';

import { styled } from '@mui/material/styles';
import { TbRadiusBottomLeft } from 'react-icons/tb';
import { red } from '@mui/material/colors';
/*import { Button } from '../Button';*/
import Button from '@mui/material/Button';
import NeedItem from './NeedItem';
import "@fontsource/open-sans";
import { useAppSelector } from '../../store';
import { API_BASE_URL } from '../../urlConfig';
import NeedResponseModal from './NeedResponseModal';

const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    border: '0px solid',
    color: theme.palette.text.secondary,
}));




const ProcessingNew = () => {
    const getToken = useToken();
    const { isAuthenticated, getAccessTokenSilently } = useAuth0();
    const [token, setToken] = useState('');
    //const contextValue = useContext(SelectedSchoolContext);
    //if (!contextValue) {
    //    // Handle the case where the context is null
    //    // This could be throwing an error, returning, rendering a different component, etc.
    //    throw new Error("SelectedSchoolContext is null");
    //}
    //const { isLoadingSchools, selectedSchoolId, selectedSchoolName } = contextValue;
    const schoolState = useAppSelector((state) => state.school.school);
    const selectedSchoolId = schoolState.id;
    const selectedSchoolName = schoolState.name;
    // useEffect(() => {
    //     if (isAuthenticated) {
    //         getAccessTokenSilently().then(token => {
    //             setLocalToken(token);
    //             console.log('token',token);
    //         });
    //     }
    // }, []);

    // useEffect(() => {
    //     if (isAuthenticated) {
    //         getAccessTokenSilently().then(token => {
    //             setLocalToken(token);
    //             console.log('token',token);
    //         });
    //     }
    // }, [isAuthenticated, getAccessTokenSilently]);

    const processingOptions = [
        { value: 0, label: "FA" },
        { value: 1, label: "Academic" },
        { value: 2, label: "Tuition" },
        { value: 3, label: "All Processing" }
    ]
    const [requirements, setRequirements] = useState<StudentRequirement[]>([]);
    // const [schoolId, setSchoolId] = useState<number>(0);
    const [error, setError] = useState('');
    const [processingType, setProcessingType] = useState<any>(processingOptions.find(x => x.value == 0));
    const [selectedStudent, setSelectedStudent] = useState<Student>();
    const [openUploadModal, setOpenUploadModal] = useState<Need | null>(null);
    const [openUploadMultipleModal, setOpenUploadMultipleModal] = useState<Need[]>([]);
    const [openFormsModal, setOpenFormsModal] = useState(false);
    //const [openDownloadModal, setOpenDownloadModal] = useState<StudentRequirement | null>(null);
    const [openNoteModal, setOpenNoteModal] = useState<Need[]>([]);//this is really the need

    const [openNeedResponseModal, setOpenNeedResponseModal] = useState(false);
 

    useEffect(() => {
        loadData();
    }, [selectedSchoolId]);


    const loadData = async () => {
        try {
            const localToken = await getToken();
           
            const { data } = await axios.get<StudentRequirement[]>
                (`${API_BASE_URL}/api/Processing/getStudentRequirements/${selectedSchoolId}`,
                    {
                        headers: { Authorization: `Bearer ${localToken?.accessToken}` }
                    });
            if (processingType?.label) {
                if (processingType?.label === 'All Processing') {
                    setRequirements(data);
                } else {
                    const filteredData = data.map((d) => {
                        const matchingNeeds = d.needs.filter(n => n.type === processingType?.label);
                        return {
                            externalStudentId: d.externalStudentId,
                            studentId: d.studentId,
                            studentName: d.studentName,
                            needs: matchingNeeds
                        };


                    });

                    setRequirements(filteredData.filter(data => data.needs.length > 0))
                }
            } else {
                setRequirements(data);
            }
        } catch ({ message }) {
            setError('An unknown error occurred' || message);
        }
    };

    const onUploadModalClose = async () => {
        setOpenUploadModal(null);
        setSelectedStudent(undefined);
        await loadData();
    }

    const onUploadMultipleModalClose = async () => {
        setOpenUploadMultipleModal([]);
        setSelectedStudent(undefined);
        await loadData();
    }


    const onNoteModalClose = async () => {
        setOpenNoteModal([]);
        setSelectedStudent(undefined);
        await loadData();
    }


    const tabStyle = {
        padding: '10px 20px',
        cursor: 'pointer',
        listStyleType: 'none'
    };

    const activeTabStyle = {
        ...tabStyle,
        borderBottom: '3px solid',
        borderRadius: '2px',


    };

    useEffect(() => {
        loadData().then(r => console.log('r', r));
      //  console.log('error', error); //this needs to be here b/c otherwise it's a warning for eslint that error is never used
    }, [processingType?.label]);

    const handleTabChange = (index: number) => {
        setProcessingType(processingOptions.find(x => x.value == index));
    };

    const updateStudentAndOpenModal = (studentInfo: Student, callback: () => void) => {
        setSelectedStudent(studentInfo);
        callback();
    };

    const updateStudentAndOpenNoteModal = (studentInfo: Student, callback: () => void) => {
        setSelectedStudent(studentInfo);
        callback();
    };

    return (
        <>

            {!selectedSchoolName && <Loader />}

            {selectedSchoolName && (
                <div>

                    <div className="pageHeader">Processing</div>
                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>

                    </div>

                    {openUploadModal &&
                        <StudentUploadModal onClose={onUploadModalClose} studentName={selectedStudent?.studentName ?? ''}
                            studentId={selectedStudent?.studentId ?? 0} schoolName={selectedSchoolName?.toString() ?? 'unknown school'} schoolId={selectedSchoolId} need={openUploadModal} />}
                    {openUploadMultipleModal.length > 0 && <StudentUploadMultipleModal onClose={onUploadMultipleModalClose}
                        studentName={selectedStudent?.studentName ?? 'unknown student Name'}
                        studentId={selectedStudent?.studentId ?? 0}
                        schoolName={selectedSchoolName?.toString() ?? 'unknown school'}
                        schoolId={selectedSchoolId}
                        needs={openUploadMultipleModal} />}
                    {openNoteModal.length > 0 && <NoteModal onClose={onNoteModalClose} studentName={selectedStudent?.studentName ?? ''}
                        studentId={selectedStudent?.studentId ?? 0}
                        schoolName={selectedSchoolName?.toString() ?? 'unknown school'}
                        schoolId={selectedSchoolId ?? 0}
                        needs={openNoteModal} />}
                    {openFormsModal && <DownloadFormsModal onClose={() => setOpenFormsModal(false)} processingType={processingType?.label } />}
              {/*      {openNeedResponseModal && <NeedResponseModal onClose={() => setOpenNeedResponseModal(false)} needId={need.id} />}*/}

                    <TableContainer sx={{ marginTop: '30px', width: '90%', maxWidth: '1400px', overflowX: 'auto', backgroundColor: 'white', borderRadius: '20px', boxShadow: '0px 0px 20px rgba(131, 151, 248, 0.13)' }}>
                        <Table >
                            <TableHead>
                                <TableRow>
                                    <TableCell colSpan={4} sx={{ border: '0px', textWrap: 'nowrap' }}>
                                        <Tabs onSelect={handleTabChange}>
                                            <TabList style={{ display: 'flex' }} >
                                                <Tab className='faTabColor' style={processingType?.value === 0 ? activeTabStyle : tabStyle}>Financial Aid</Tab>
                                                <Tab className='academicTabColor' style={processingType?.value === 1 ? activeTabStyle : tabStyle}>Academic</Tab>
                                                <Tab className='tuitionTabColor' style={processingType?.value === 2 ? activeTabStyle : tabStyle}>Tuition</Tab>
                                                <Tab className='viewAllTabColor' style={processingType?.value === 3 ? activeTabStyle : tabStyle}>View All</Tab>
                                            </TabList>
                                        </Tabs>

                                    </TableCell>
                                    <TableCell colSpan={1} sx={{ border: '0px', textAlign: 'right', paddingRight: '30px' }}>
                                        <Button onClick={() => setOpenFormsModal(true)} variant="contained" style={{ backgroundColor: '#495DDB' }}>Download Forms
                                        </Button>
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                <TableRow>
                                    <TableCell sx={{ border: '0px' }} colSpan={5}>
                                        {requirements.map((requirement, index) => (
                                            <Box key={index} >
                                                <Typography sx={{ fontSize: 16, padding: 2, paddingBottom: 0, marginBottom: '0px' }} color="#253872" gutterBottom>
                                                    <strong>{requirement.studentName}</strong> #{requirement.externalStudentId}
                                                </Typography> <Box sx={{ minWidth: 275, padding: '14px', paddingTop: '4px' }}>
                                                    <Card sx={{ minWidth: 275, minHeight: '85px', display: 'flex' }} variant="outlined" >
                                                        <Item key={requirement.studentId} style={{ width: '90%', borderRadius: '0' }} variant="outlined">
                                                            <Stack paddingLeft='10px' paddingTop='10px' spacing={2}>
                                                                {requirement.needs.map((need, index) => (
                                                                    <NeedItem need={need} />

                                                                ))}

                                                            </Stack></Item>
                                                        {processingType?.value != 3 && <Item style={{ borderRadius: '0' }}>
                                                            <Box sx={{ display: 'flex', paddingLeft: '4px' }} >
                                                                <div onClick={() => {
                                                                    updateStudentAndOpenModal({
                                                                        studentName: requirement.studentName,
                                                                        studentId: requirement.externalStudentId
                                                                    }, () => setOpenUploadMultipleModal(requirement.needs));
                                                                }}
                                                                >
                                                                    <NeedUploadIcon />
                                                                </div>
                                                                <div style={{ paddingLeft: '11px' }} onClick={() => {
                                                                    updateStudentAndOpenNoteModal({
                                                                        studentName: requirement.studentName,
                                                                        studentId: requirement.externalStudentId
                                                                    }, () => setOpenNoteModal(requirement.needs));
                                                                }}
                                                                >
                                                                    <NeedCommentIcon />
                                                                </div>
                                                            </Box></Item>}

                                                    </Card></Box>
                                            </Box>

                                        ))}
                                    </TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                    </TableContainer>



                </div>
            )}
        </>
    );
};

export default ProcessingNew;

