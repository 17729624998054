import React, { useContext, useEffect, useState } from 'react';
import axios from 'axios';
import { useAuth0 } from "@auth0/auth0-react";
import { Department, Role, UserModel } from "../../types";
import { Paper, Grid, Button, TextField, MenuItem, InputLabel, Select, FormControl, OutlinedInput, SelectChangeEvent } from '@mui/material';
/*import Select from "react-select";*/
import SelectedSchoolContext from "../SelectedSchoolContext";
import SchoolSelector from "../SchoolSelector";
import { useToken } from "../../useToken";
import { useNavigate } from 'react-router-dom';
import '../../styles.css';
import { useAppSelector } from '../../store';
import { API_BASE_URL } from '../../urlConfig';

const customStyles = {
    container: (provided: any) => ({
        ...provided,
        width: '100%',
    }),
    control: (provided: any, state: any) => ({
        ...provided,
        boxShadow: state.isFocused && '1px solid #253872',
        border: state.isFocused ? '1px solid #253872' : '1px solid #e5e5ea',
        height: 48,
        '&:hover': {
            border: state.isFocused && '1px solid #253872',

        },
    }),
};

const CreateUser = () => {
    //const contextValue = useContext(SelectedSchoolContext);
    //if (!contextValue) {
    //    // Handle the case where the context is null
    //    // This could be throwing an error, returning, rendering a different component, etc.
    //    throw new Error("SelectedSchoolContext is null");
    //}
    //const { isLoadingSchools, selectedSchoolId } = contextValue;
    const schoolState = useAppSelector((state) => state.school.school);
    const selectedSchoolId = schoolState.id;
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [response, setResponse] = useState(null);
    const [success, setSuccess] = useState(false);
    //const baseUrl = process.env.REACT_APP_API_BASE_URL;
    const { getAccessTokenSilently } = useAuth0();
    const [passwordError, setPasswordError] = useState<string>("");
    const [firstNameError, setFirstNameError] = useState<string>("");
    const [lastNameError, setLastNameError] = useState<string>("");
    const [roleError, setRoleError] = useState<string>("");
    const [typeError, setTypeError] = useState<string>("");
    const [titleError, setTitleError] = useState<string>("");
    const [verifyPassword, setVerifyPassword] = useState<string>("");
    const [error, setError] = useState('');
    const [roles, setRoles] = useState<Role[]>();
    const [departmentOptions, setDepartmentOptions] = useState<Department[]>([]);
    const [departments, setDepartments] = React.useState<string[]>([]);

    const validateEmail = (email: string) => {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailRegex.test(email);
    };
    const [emailError, setEmailError] = useState("");

    const validatePassword = (password: string) => {
        const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;
        return passwordRegex.test(password);
    };
    const [userModel, setUserModel] = useState<UserModel>({
        email: "",
        user_metadata: { name: "" },
        blocked: false,
        email_verified: false,
        app_metadata: {},
        given_Name: "",
        family_Name: "",
        name: "",
        nickname: "nickname",
        picture: "https://example.com/image.jpg",
        user_Id: Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15),
        connection: "Username-Password-Authentication",
        password: "",
        verify_email: false,
        schoolId: selectedSchoolId ?? 0,
        role_id: "",
        user_type: "",
       departments:[],
    });


    const userTypes = [
        { value: "HES", label: "HES" },
        { value: "School", label: "School" }
    ];

    const titles = [      
        { value: "Miss", label: "Miss" },
        { value: "Mr", label: "Mr" },
        { value: "Mrs", label: "Mrs" },      
        { value: "Ms", label: "Ms" },
        { value: "Rabbi", label: "Rabbi" },
        { value: "Rebbetzin", label: "Rebbetzin" },
    ];


    const ITEM_HEIGHT = 48;
    const ITEM_PADDING_TOP = 8;
    const MenuProps = {
        PaperProps: {
            style: {
                maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
                width: 250,
            },
        },
    };

   

    const handleDepartmentChange = (event: SelectChangeEvent<typeof departments>) => {
        const {
            target: { value },
        } = event;
        setDepartments(
            // On autofill we get a stringified value.
            typeof value === 'string' ? value.split(',') : value,
        );
       // setUserModel({ ...userModel, departments: typeof value === 'string' ? value.split(',') : value })
    };

    const getToken = useToken();

    const createUser = async () => {
        setLoading(true);
        const localToken = await getToken();
        setUserModel({ ...userModel, user_metadata: { name: userModel.name + " " + userModel.family_Name } })

        axios.post(`${API_BASE_URL}/api/Login/createUser`, userModel, {
            headers:
            {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${localToken?.accessToken}`
            }
        })
            .then((res) => {
                if (res.status === 200) {
                   // console.log(res);
                    setSuccess(true);
                    //console.log(success);
                    setLoading(false);
                    alert("Success creating user");
                    setUserModel({
                        email: "",
                        user_metadata: { name: "" },
                        blocked: false,
                        email_verified: false,
                        app_metadata: {},
                        given_Name: "string",
                        family_Name: "",
                        name: "",
                        nickname: "nickname",
                        picture: "https://example.com/image.jpg",
                        user_Id: Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15),
                        connection: "Username-Password-Authentication",
                        password: "",
                        verify_email: false,
                        schoolId: selectedSchoolId ?? 0,
                        role_id: "",
                        user_type: "",
                        //departments: [],
                    });
                    setVerifyPassword('');
                } else {
                  //  console.log('Error creating user!  ');
                 //   console.log(res);
                    setResponse(res.data);
                    setLoading(false);
                    alert(res.statusText + " Error creating user---  " + res.data);

                }
            })

            .catch((error) => {
                setResponse(error?.response?.data);
                setLoading(false);
                alert("Error creating user---  " + error?.response?.data);
            });
    }

    const getRoles = async () => {
        try {
            const localToken = await getToken();

            const { data } = await axios.get(`${API_BASE_URL}/api/Admin/GetRoles`, {
                headers:
                {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${localToken?.accessToken}`
                }
            });
            const filteredData = data?.filter((role: { name: string; }) => role.name != 'gmailRule' && role.name != 'Admin' && role.name != 'HESManager');
            setRoles(filteredData);
        } catch (error: any) {
            setError(error.message || 'An unknown error occurred');
        }
    };


    const getDepartments = async () => {
        try {
            const localToken = await getToken();

            const { data } = await axios.get(`${API_BASE_URL}/api/Admin/getDepartments`, {
                headers:
                {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${localToken?.accessToken}`
                }
            });
            setDepartmentOptions(data);
        } catch (error: any) {
            setError(error.message || 'An unknown error occurred');
        }
    };


    useEffect(() => {
        getRoles();
      //  getDepartments();
    }, []);
    return (
        <div>
            <div><SchoolSelector /></div>
            <br />
            <Grid container spacing={2}>
                <Grid item xs={12} sm={8} md={6}>
                    <Paper style={{ padding: 16 }}>
                        <form noValidate autoComplete="off">
                            <span className="pageHeaderAccountSettings" >Create User</span>
                            <TextField
                                fullWidth
                                label="Email"
                                value={userModel.email}
                                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                    const email = e.target.value;
                                    setUserModel({ ...userModel, email: email });
                                    if (!validateEmail(email)) {
                                        setEmailError("Please enter a valid email.");
                                    } else {
                                        setEmailError("");
                                    }
                                }}
                                error={!!emailError}
                                helperText={emailError}
                                margin={'normal'}
                            />
                            <FormControl margin={'normal'} fullWidth><InputLabel>Title</InputLabel>
                                <Select
                                    value={userModel.given_Name}
                                    label="Title"
                                    error={!!titleError}
                                    onChange={(e) => setUserModel({ ...userModel, given_Name: (e.target.value) })}
                                >
                                    {titles &&
                                        titles.map(x => (
                                            <MenuItem value={x.value}>{x.label}</MenuItem>))}
                                </Select></FormControl>

                            <TextField
                                fullWidth
                                label="First Name"
                                value={userModel.name}
                                onChange={(e: React.ChangeEvent<HTMLInputElement>) => setUserModel({ ...userModel, name: e.target.value })}
                                margin={'normal'}
                                error={!!firstNameError}
                                helperText={firstNameError}
                            />
                            <TextField
                                fullWidth
                                label="Last Name"
                                value={userModel.family_Name} margin={'normal'}
                                error={!!lastNameError}
                                helperText={lastNameError}
                                onChange={(e: React.ChangeEvent<HTMLInputElement>) => setUserModel({ ...userModel, family_Name: e.target.value })}
                            />
                            <TextField
                                fullWidth
                                label="Password"
                                type="password"
                                value={userModel.password}
                                onChange={(e) => {
                                    const password = e.target.value;
                                    setUserModel({ ...userModel, password: password });
                                    if (!validatePassword(password)) {
                                        setPasswordError("Password must be at least 8 characters long and contain at least one lowercase letter, one uppercase letter, one digit, and one special character.");
                                    } else {
                                        setPasswordError("");
                                    }
                                }}
                                error={!!passwordError}
                                helperText={passwordError} margin={'normal'}
                            />
                            <TextField
                                fullWidth
                                label="Verify Password"
                                type="password"
                                value={verifyPassword}
                                onChange={(e) => setVerifyPassword(e.target.value)} margin={'normal'}
                            />

                            <FormControl margin={'normal'} fullWidth><InputLabel>Assign Role</InputLabel>
                                <Select
                                    value={userModel.role_id}
                                    label="Assign Role"
                                    error={!!roleError}
                                    onChange={(e) => setUserModel({ ...userModel, role_id: (e.target.value) })}
                                >
                                    {roles &&
                                        roles.map(x => (
                                            <MenuItem value={x.id}>{x.description}</MenuItem>))}
                                </Select></FormControl>

                            <FormControl margin={'normal'} fullWidth><InputLabel>User Type</InputLabel>
                                <Select
                                    value={userModel.user_type}
                                    label="User Type"
                                    error={!!typeError}
                                    onChange={(e) => setUserModel({ ...userModel, user_type: (e.target.value) })}
                                >
                                    {userTypes &&
                                        userTypes.map(x => (
                                            <MenuItem value={x.value}>{x.label}</MenuItem>))}
                                </Select></FormControl>

                            {/*<FormControl margin={'normal'}  fullWidth>*/}
                            {/*    <InputLabel id="demo-multiple-name-label">Department</InputLabel>*/}
                            {/*    <Select*/}
                            {/*        labelId="demo-multiple-name-label"*/}
                            {/*        id="demo-multiple-name"*/}
                            {/*        multiple*/}
                            {/*        value={[]}*/}
                            {/*        onChange={handleDepartmentChange}*/}
                            {/*        input={<OutlinedInput label="Department" />}*/}
                            {/*        MenuProps={MenuProps}*/}
                            {/*    >*/}
                            {/*        {departmentOptions.map((dep) => (*/}
                            {/*            <MenuItem*/}
                            {/*                key={dep.departmentid}*/}
                            {/*                value={dep.departmentname}*/}
                            {/*            >*/}
                            {/*                {dep.departmentname}*/}
                            {/*            </MenuItem>*/}
                            {/*        ))}*/}
                            {/*    </Select>*/}
                            {/*</FormControl>*/}



                            <div style={{ marginTop: "10px" }}>     <Button variant="contained" disabled={loading} style={{ backgroundColor: '#253872' }}
                                onClick={() => {
                                    if (userModel.name == '') {
                                        setFirstNameError("Please enter a First Name.");
                                    }
                                    else { setFirstNameError(""); }
                                    if (userModel.family_Name == '') {
                                        setLastNameError("Please enter a Last Name.");
                                    } else {
                                        setLastNameError("");
                                    }
                                    if (userModel.role_id == '') {
                                        setRoleError("Please select a Role.");
                                    } else { setRoleError(""); }

                                    if (userModel.user_type == '') {
                                        setTypeError("Please select a User Type.");
                                    } else { setTypeError(""); }

                                    if (userModel.given_Name == '') {
                                        setTitleError("Please select a Title.");
                                    } else { setTitleError(""); }
                                    if (userModel.password !== verifyPassword) {
                                        setPasswordError("Passwords do not match!");
                                    }
                                    else if (!validatePassword(userModel.password)) {
                                        setPasswordError("Password does not meet the requirements!");
                                    } else {
                                        setPasswordError("");
                                    }
                                    if (userModel.password == verifyPassword && validatePassword(userModel.password) && passwordError == "" && userModel.name != "" && firstNameError == "" && lastNameError == "" && roleError == "" && userModel.given_Name != "") {
                                        createUser();
                                    }

                                }} >
                                {loading ? 'Creating...' : 'Create User'}
                               
                            </Button>
                                <Button variant="outlined" style={{ borderColor: '#253872', color: '#253872', marginLeft: '5px' }} onClick={() => navigate('/manageusers')}>Cancel</Button>
                                {response && <div>{response}</div>} </div>
                        </form>
                    </Paper>
                </Grid>
            </Grid>

        </div>
    );

}

export default CreateUser;